import React from "react";

// Routes
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Edit from "./components/Edit";
import Home from "./components/Home";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/edit/:resumeId" element={<Edit />} />
      </Routes>
    </BrowserRouter>
  );
}
