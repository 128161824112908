import React from "react";
import defaultValues from "../../data/defaultValues";
import Input from "../resume1components/Input";

export default function Template() {
  const { name, role, email, phone, address, linkedin } = defaultValues[0];

  const handleChange = () => {
    //
  };
  return (
    <div className="p-4 bg-white">
      <Input label="Full Name" type="text" defaultValue={name} />
      <Input label="Job Role" type="text" defaultValue={role} />
      <Input label="Email" type="email" defaultValue={email} />
      <Input
        label="Phone Number"
        type="number"
        defaultValue={phone.phoneNumber}
      />
      <Input label="Address" type="text" defaultValue={address.city} />
      <Input label="LinkedIn" type="text" defaultValue={linkedin} />
    </div>
  );
}
