import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Navbar() {
  const notify = () =>
    toast.info("Coming Soon!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return (
    <>
      <div className="fixed-top nav">
        <div className="container">
          <nav className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 ">
            <Link
              to="/"
              className="d-flex align-items-center col-md-4 mb-2 mb-md-0 text-dark text-decoration-none fs-3"
            >
              Resume Builder
            </Link>
            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
              <li>
                <a href="#" className="nav-link px-2 link-secondary">
                  Home
                </a>
              </li>
              <li>
                <a href="#hero" className="nav-link px-2 link-dark">
                  Templates
                </a>
              </li>

              <li>
                <a href="#footer" className="nav-link px-2 link-dark">
                  About
                </a>
              </li>
            </ul>
            <div className="col-md-3 text-end">
              <button
                type="button"
                class="btn px-4 btn-outline-dark"
                onClick={notify}
              >
                Login
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div id="circle-2"></div>
      <ToastContainer />
    </>
  );
}
