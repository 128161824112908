const defaultValues = [
  {
    color: "#5a20cb",
    dname: "Adam Brandon",
    role: "Project Manager",
    email: "adambrandon@gmail.com",
    phone: {
      countryCode: "+91",
      phoneNumber: "1234567890",
    },
    address: {
      city: "Ahmedabad",
      state: "Gujarat",
      country: "India",
    },
    linkedin: "http://linkedin.com/",
    summary:
      "I manage the software development projects and facilitate sprint releases. I recommend upcoming software trends and strategies to be used in the projects. I manage the software development projects and facilitate sprint releases. I recommend upcoming software trends and strategies to be used in the projects",
    skills: [
      ["Excel", 5],
      ["Data Analyst", 3],
      ["Machine Learning", 4],
    ],
    experience: [
      {
        jobRole: "Data Analyst",
        companyName: "MS Technologies Private Limited",
        jobtiming: {
          startDate: "Oct 2016",
          endDate: "Apr 2017",
        },
        jobDescription: "Visualizing Data",
      },
      {
        jobRole: "MS Excel",
        companyName: "MS Technologies Private Limited",
        jobtiming: {
          startDate: "Oct 2016",
          endDate: "Apr 2017",
        },
        jobDescription: "Managing all the data with MS Excel",
      },
    ],
    education: [
      {
        degree: "Diploma in Computer Science",
        collegeName: "National Diploma Center",
        degreetiming: {
          startDate: "Oct 2021",
          endDate: "Apr 2019",
        },
      },
      {
        degree: "12th",
        collegeName: "Sant. Marry School",
        degreetiming: {
          startDate: "Oct 2017",
          endDate: "Apr 2019",
        },
      },
    ],
    extraActivites: [
      {
        activityName: "Gold Medal in National Badminton Championship",
        activityDescription:
          "I participated in national badminton chamption which I achieved gold medal.",
      },
    ],
    languages: [
      ["Hindi", 4],
      ["English", 5],
    ],
    awards: ["National Sports Leaders Award", "Silver in Mens Kabaddi League"],
  },
];

export default defaultValues;
