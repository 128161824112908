import React from "react";
import defaultValues from "../../data/defaultValues";
// css
import "../../css/edit/edit.css";

// img
import Pencil from "../../img/pencil.svg";

export default function Arrow({ setEditBox }) {
  let color = defaultValues[0].color;
  return (
    <span
      className="pencil-box"
      style={{ backgroundColor: color }}
      onClick={() => {
        setEditBox("1");
      }}
    >
      <img src={Pencil} alt="" className="pencil" />
    </span>
  );
}
