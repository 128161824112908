import React from "react";

import "../../css/edit/resume4.css";

export default function Resume4() {
  return (
    <div id="resume4" className="container p-3 bg-white">
      <header>
        {/* name  */}
        <div className="names">
          <h1 className="name">Adam Brandon</h1>
          <h5>Project Manager</h5>
        </div>
        {/* contact : Address, mobile , email and linkedIn  */}
        <div className="contact row">
          <div className="col-12 col-sm-5 mb-2 phone contact-text">
            <strong className="me-1">Phone:</strong> +91 9106336967
          </div>
          <div className="col-12 col-sm-5 mb-2 email contact-text">
            <strong className="me-1">Email:</strong>theankitpurohit@gmail.com
          </div>
          <div className="col-12 col-sm-5 mb-2 address contact-text">
            <strong className="me-1">Address:</strong>Ahmedabad, Gujarat
          </div>
          <div className="col-12 col-sm-5 mb-2 address contact-text">
            <strong className="me-1">LinkedIn:</strong>
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className=" contact-text"
            >
              linkedin.com/in/theankitpurohit
            </a>
          </div>
        </div>
        {/* summary  */}
        <div className="summary mt-3 small">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Exercitationem ad alias atque ullam, rem voluptatem sed illo ipsam
          nulla distinctio minima nobis commodi eligendi dignissimos? Facilis
          iste, iure veniam tempore recusandae dolor. Hic ratione porro delectus
          vero cumque culpa sint atque minus blanditiis ab, fugit dolores
          excepturi at possimus quaerat.
        </div>
      </header>
      {/* experience */}
      <section id="experience-container">
        <h1 className="h-primary">Experience</h1>
        <div id="experience">
          <div className="eperience-box row">
            <div className="timing h6 mt-1 col-3 col-md-2">
              2006-12 - Present
            </div>
            <div className="ms-3 col-8 col-md-9">
              <h4>Senior Project Manager</h4>
              <p>Senton Hospital, ME</p>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
              </ul>
            </div>
          </div>
          <div className="eperience-box row">
            <div className="timing h6 mt-1 col-3 col-md-2">
              2006-12 - Present
            </div>
            <div className="ms-3 col-8 col-md-9">
              <h4>Senior Project Manager</h4>
              <p>Senton Hospital, ME</p>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* education  */}
      <section id="education-container">
        <h1 className="h-primary">Education</h1>
        <div id="education">
          <div className="education-box row">
            <div className="timing h6 mt-1 col-3 col-md-2">
              2006-12 - Present
            </div>
            <div className="ms-3 col-8 col-md-9">
              <h4>Senior Project Manager</h4>
              <p>Senton Hospital, ME</p>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  earum nostrum quidem consectetur quibusdam reiciendis.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* skills  */}
      <section id="skills-container">
        <h1 className="h-primary">Skills</h1>
        <div id="skills" className="row">
          <div className="skill-box col-10 ms-auto mb-2">
            <strong> Business Process Improvement </strong>
            <small>
              history of successful innovations leading to cost savings.
            </small>
          </div>
          <div className="skill-box col-10 ms-auto mb-2">
            <strong> Business Process Improvement </strong>
            <small>
              history of successful innovations leading to cost savings.
            </small>
          </div>
          <div className="skill-box col-10 ms-auto mb-2">
            <strong> Business Process Improvement </strong>
            <small>
              history of successful innovations leading to cost savings.
            </small>
          </div>
          <div className="skill-box col-10 ms-auto mb-2">
            <strong> Business Process Improvement </strong>
            <small>
              history of successful innovations leading to cost savings.
            </small>
          </div>
        </div>
      </section>
      {/* software  */}
      <section id="software">
        <h1 className="h-primary">Software</h1>
        <div id="software">
          <div className="software-box d-flex justify-content-between mb-2">
            <p>MS Office</p>
            <div>
              <i className="fa-solid fa-circle checked" />
              <i className="fa-solid fa-circle checked" />
              <i className="fa-solid fa-circle" />
              <i className="fa-solid fa-circle" />
              <i className="fa-solid fa-circle" />
              <div>Excellent</div>
            </div>
          </div>
          <div className="software-box d-flex justify-content-between mb-2">
            <p>MS Office</p>
            <div>
              <i className="fa-solid fa-circle checked" />
              <i className="fa-solid fa-circle checked" />
              <i className="fa-solid fa-circle" />
              <i className="fa-solid fa-circle" />
              <i className="fa-solid fa-circle" />
              <div>Excellent</div>
            </div>
          </div>
        </div>
      </section>
      {/* certifications */}
      <section id="certifications">
        <h1 className="h-primary">Certifications</h1>
        <div id="certificate">
          <div className="certificate-box row mb-2">
            <div className="timing h6 mt-1 col-3 col-md-2">2010 - 05</div>
            <div className="ms-3 col-8 col-md-9">
              PMP - Project Management Institute
            </div>
          </div>
          <div className="certificate-box row mb-2">
            <div className="timing h6 mt-1 col-3 col-md-2">2010 - 05</div>
            <div className="ms-3 col-8 col-md-9">
              PMP - Project Management Institute
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
