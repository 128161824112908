import React from "react";
import "../../css/edit/resume2.css";

export default function Resume2() {
  return (
    <div id="resume2" className=" bg-white">
      <main className="row">
        <aside className="col-4" id="left-container">
          {/* photo  */}
          <div className="brand mt-3 mx-auto rounded-circle ">
            <div className="logo "></div>
          </div>
          {/* contact  */}
          <section id="contact-container" className="mt-4">
            <h4 className="h-secondary">CONTACT INFO</h4>
            <div id="contact">
              <div className="contact-box mb-2">
                <i className="fa-solid fa-location-dot" />
                <small className="ms-2 contact-text">
                  Ahmedabad, Gujarat, India
                </small>
              </div>
              <div className="contact-box mb-2">
                <i className="fa-solid fa-envelope" />
                <small className="ms-2 contact-text">
                  adambrandon@gmail.com
                </small>
              </div>
              <div className="contact-box mb-2">
                <i className="fa-solid fa-mobile-screen" />
                <small className="ms-2 contact-text">+91 9106336967</small>
              </div>
              <div className="contact-box contact-box-4 mb-2 d-flex">
                <i className="fa-solid fa-location-dot" />
                <a
                  href="http://linkedIn.com/in/theankitpurohit"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social d-flex justify-content-between ms-2"
                >
                  <small className="text-white contact-text">
                    linkedIn.com/in/theankitpurohit
                  </small>
                </a>
              </div>
            </div>
          </section>
          {/* skills  */}
          <section id="skills-container" className="mt-5">
            <h4 className="h-secondary">SKILLS SUMMARY</h4>
            <div id="skills">
              <div className="skill-box d-flex">
                <div className="stars">
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                </div>
                <small className="ms-4">Python</small>
              </div>
              <div className="skill-box d-flex">
                <div className="stars">
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                </div>
                <small className="ms-4">Python</small>
              </div>
              <div className="skill-box d-flex">
                <div className="stars">
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                </div>
                <small className="ms-4">Python</small>
              </div>
              <div className="skill-box d-flex">
                <div className="stars">
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                </div>
                <small className="ms-4">Python</small>
              </div>
              <div className="skill-box d-flex">
                <div className="stars">
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle checked" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                  <i className="fa-solid fa-circle" />
                </div>
                <small className="ms-4">Python</small>
              </div>
            </div>
          </section>
          {/* awards  */}
          <section id="awards-container" className="mt-5">
            <h4 className="h-third">AWARDS RECEIVED</h4>
            <ul id="awards">
              <li>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
                ipsa unde culpa doloremque natus nam odit id ex eos placeat!
              </li>
              <li>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
                ipsa unde culpa doloremque natus nam odit id ex eos placeat!
              </li>
              <li>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
                ipsa unde culpa doloremque natus nam odit id ex eos placeat!
              </li>
            </ul>
          </section>
        </aside>
        {/* RIGHT SIDE  */}
        <aside className="col-8" id="right-container">
          {/* heading  */}
          <div className="heading my-5">
            <h1>OLIVIA WILSON</h1>
            <hr style={{ width: "30%", border: "" }} />
            <h5 className="text-blue">ML Engineer</h5>
          </div>
          {/* summary  */}
          <section id="summary-container" className="mb-4">
            <h3 className="h-primary text-blue">PERSONAL PROFILE</h3>
            <p className="small">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Consequatur temporibus placeat dolor quis reiciendis quo dolorum
              libero, unde quos natus.
            </p>
          </section>
          {/* experience */}
          <section id="experience-container" className="mb-4">
            <h3 className="h-primary text-blue">WORK EXPERIENCE</h3>
            <div id="experience">
              <div className="experience-box">
                <h4 className="company mt-4">OzeneAI</h4>
                <div className="text-muted small">
                  ML Enginner | Apr 2019 - Present
                </div>
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia rerum debitis qui dignissimos quos vel beatae
                    aspernatur aliquid est? Explicabo nihil modi temporibus odio
                    accusamus voluptatibus, hic ipsam voluptates perspiciatis
                    magni nobis eos? Pariatur, dolores!
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia rerum debitis qui dignissimos quos vel beatae
                    aspernatur aliquid est? Explicabo nihil modi temporibus odio
                    accusamus voluptatibus, hic ipsam voluptates perspiciatis
                    magni nobis eos? Pariatur, dolores!
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {/* project  */}
          <section id="project-container" className="mb-4">
            <h3 className="h-primary text-blue">PROJECTS</h3>
            <div id="project">
              <div className="project-box">
                <h4 className="project-name mt-4">Campus Event</h4>
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facilis magni reiciendis tempore inventore esse ad sed, quo
                    deleniti minima sint est provident exercitationem ut
                    suscipit commodi nihil voluptatem. Possimus adipisci quos
                    deleniti facere corporis expedita saepe accusamus voluptate
                    ipsam veritatis.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facilis magni reiciendis tempore inventore esse ad sed, quo
                    deleniti minima sint est provident exercitationem ut
                    suscipit commodi nihil voluptatem. Possimus adipisci quos
                    deleniti facere corporis expedita saepe accusamus voluptate
                    ipsam veritatis.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {/* education  */}
          <section id="education-container" className="mb-4">
            <h3 className="h-primary text-blue">EDUCATION</h3>
            <div id="education">
              <div className="education-box">
                <h4 className="college mt-4">Gujarat Universiry</h4>
                <p className="text-muted small">
                  BSC in Computer Science | Sep 2015 - Apr 2019
                </p>
                <ul>
                  <li>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Reprehenderit, laborum!
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Esse, iure!
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Harum sapiente error praesentium ullam excepturi maiores,
                    iusto labore et incidunt doloribus.
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </aside>
      </main>
    </div>
  );
}
