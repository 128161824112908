import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Footer and Navbar
import Footer from "../components/homeComponents/Footer";
import Navbar from "../components/homeComponents/Navbar";

// Resume Information
import ResumeInfo from "../data/resumeInfo";

// css
import "../css/utility.css";
import "../css/edit/edit.css";
import EmptyBox from "./editComponents/EmptyBox";
import Template from "./editComponents/Template";
import defaultValues from "../data/defaultValues";

export default function Edit() {
  const { resumeId } = useParams();
  // const [name, setName] = useState("Ankit");
  const [editBox, setEditBox] = useState("0");
  const {
    dname,
    role,
    email,
    phone,
    address,
    linkedin,
    summary,
    skills,
    experience,
    education,
    extraActivites,
    languages,
    awards,
  } = defaultValues[0];

  // state
  const [name, setName] = useState("Ankit");

  let defulatComponet;
  if (editBox === "0") {
    defulatComponet = <EmptyBox />;
  } else if (editBox === "1") {
    defulatComponet = <Template />;
  }

  return (
    <>
      <Navbar />
      <div className="edit-container pb-5">
        <div className="container">
          <div className="row justify-content-between">
            {/* Action window */}
            <div className="col-lg-6">
              <h4 className="my-3">Action window</h4>
              {defulatComponet}
              {/* <Template /> */}
            </div>
            {/* Template View */}
            <div className="col-lg-6 ">
              <h4 className="my-3">Template View</h4>
              {ResumeInfo.map((resume, id) => {
                if (resume.id === resumeId) {
                  return resume.component({ setEditBox, name, setName });
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
