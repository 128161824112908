// Images
import Resume1Img from "../img/resume/1.png";
import Resume2Img from "../img/resume/2.jpg";
import Resume3Img from "../img/resume/3.jpg";
import Resume4Img from "../img/resume/4.jpg";

// Components
import Resume1 from "../components/editComponents/Resume1";
import Resume2 from "../components/editComponents/Resume2";
import Resume3 from "../components/editComponents/Resume3";
import Resume4 from "../components/editComponents/Resume4";

// Information
const ResumeInfo = [
  {
    name: "Resume1",
    image: Resume1Img,
    id: "resume1",
    component: ({ setEditBox, name, setName }) => {
      return <Resume1 setEditBox={setEditBox} name={name} setName={setName} />;
    },
  },
  {
    name: "Resume2",
    image: Resume2Img,
    id: "resume2",
    component: (id) => {
      return <Resume2 />;
    },
  },
  {
    name: "Resume3",
    image: Resume3Img,
    id: "resume3",
    component: (id) => {
      return <Resume3 />;
    },
  },
  {
    name: "Resume4",
    image: Resume4Img,
    id: "resume4",
    component: (id) => {
      return <Resume4 />;
    },
  },
];

export default ResumeInfo;
