import React from "react";

export default function Input({ label, type, defaultValue }) {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <input
        type={type}
        className="form-control"
        onChange={(e) => console.log(e.target.value)}
        defaultValue={defaultValue}
      />
    </div>
  );
}
