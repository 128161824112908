import React from "react";

export default function Hero() {
  return (
    <div className="hero py-5 text-center text-light" id="hero">
      <div className="py-5 container">
        <h1 className="fw-bold">Resume Templates</h1>
        <p>
          Pick a resume template, fill it out, and format it. Create a
          professional resume in a few clicks. Just choose one of the resume
          templates below, add ready-made content, download it, and get the job.
        </p>
      </div>
    </div>
  );
}
