import React from "react";
import "../../css/edit/resume3.css";

export default function Resume3() {
  return (
    <div id="resume3" className="bg-white container py-3">
      <header>
        <h1 className="name">Purohit Ankit Ranjitsinh</h1>
        {/* contact : Address, mobile , email and linkedIn  */}
        <div className="contact mt-4 row gy-2">
          <div className="social col-12 col-sm-6 col-md-4 d-flex align-items-center">
            <i className="fa-solid fa-location-dot" />
            <span className="contact-text">
              <small> Ahmedabad, Gujarat </small>
            </span>
          </div>
          <div className="social col-12 col-sm-6 col-md-3 d-flex align-items-center">
            <i className="fa-solid fa-mobile-screen" />
            <span className="contact-text">
              <small> +91 9106336967</small>
            </span>
          </div>
          <div className="social col-12 col-sm-6 col-md-5 d-flex align-items-center">
            <i className="fa-solid fa-envelope" />
            <span className="contact-text">
              <small> theankitpurohit@gmail.com</small>
            </span>
          </div>
          <div className="social col-12 col-sm-6 col-md-4 d-flex align-items-center">
            <i className="fa-solid fa-mobile-screen" />
            <a
              href="http://linkedIn.com/in/theankitpurohit"
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <small className="contact-text">
                linkedIn.com/in/theankitpurohit
              </small>
            </a>
          </div>
        </div>
      </header>
      <div className="row">
        <div className="left-side col-5">
          {/* education  */}
          <section id="education-container">
            <h1 className="h-primary">EDUCATION</h1>
            <div id="education">
              <div className="education-box mb-2">
                <div className="education-subject small">
                  Bachelor of Commerce
                </div>
                <h5 className="education-college my-2 text-purple">
                  Gujarat University
                </h5>
                <p className="education-date">
                  <i className="fa-solid fa-calendar me-2" /> Aug 2019 - Apr
                  2022
                </p>
                <ul className="education-percentage">
                  <li>82%</li>
                </ul>
              </div>
              <div className="education-box mb-2">
                <div className="education-subject small">
                  Bachelor of Commerce
                </div>
                <h5 className="education-college my-2 text-purple">
                  Gujarat University
                </h5>
                <p className="education-date">
                  <i className="fa-solid fa-calendar me-2" /> Aug 2019 - Apr
                  2022
                </p>
                <ul className="education-percentage">
                  <li>82%</li>
                </ul>
              </div>
              <div className="education-box mb-2">
                <div className="education-subject small">
                  Bachelor of Commerce
                </div>
                <h5 className="education-college my-2 text-purple">
                  Gujarat University
                </h5>
                <p className="education-date">
                  <i className="fa-solid fa-calendar me-2" /> Aug 2019 - Apr
                  2022
                </p>
                <ul className="education-percentage">
                  <li>82%</li>
                </ul>
              </div>
            </div>
          </section>
          {/* projects  */}
          <section id="projects-container">
            <h1 className="h-primary">PROJECTS</h1>
            <div id="projects">
              <div className="project-box mb-3">
                <h5>Virtual Mini Lab Developmet</h5>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Optio repellendus non quasi quibusdam voluptatum et iste
                  debitis quo odio! Commodi.
                </p>
              </div>
              <div className="project-box mb-3">
                <h5>Virtual Mini Lab Developmet</h5>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Optio repellendus non quasi quibusdam voluptatum et iste
                  debitis quo odio! Commodi.
                </p>
              </div>
              <div className="project-box mb-3">
                <h5>Virtual Mini Lab Developmet</h5>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Optio repellendus non quasi quibusdam voluptatum et iste
                  debitis quo odio! Commodi.
                </p>
              </div>
            </div>
          </section>
          {/* trainings  */}
          <section id="trainings-container">
            <h1 className="h-primary">TRAININGS</h1>
            <div id="trainings">
              <div className="trainings-box mb-3">
                <div className="training-name small">
                  Underground Vocational Training, Kunustoria Colliery
                </div>
                <h5 className="training-institute text-purple">
                  Eastern Coalfield Limited
                </h5>
                <p className="training-period">
                  <i className="fa-solid fa-calendar me-2" /> May 2019
                </p>
              </div>
              <div className="trainings-box mb-3">
                <div className="training-name small">
                  Underground Vocational Training, Kunustoria Colliery
                </div>
                <h5 className="training-institute text-purple">
                  Eastern Coalfield Limited
                </h5>
                <p className="training-period">
                  <i className="fa-solid fa-calendar me-2" /> May 2019
                </p>
              </div>
            </div>
          </section>
        </div>
        {/* RIGHT SIDE  */}
        <div className="right-side col-7">
          {/* skills  */}
          <section id="skills-container">
            <h1 className="h-primary">SKILLS</h1>
            <div id="skills" className="row">
              <div className="skill-box col-4 col-sm-3 col-md-2">C++</div>
              <div className="skill-box col-4 col-sm-3 col-md-2">C++</div>
              <div className="skill-box col-4 col-sm-3 col-md-2">C++</div>
              <div className="skill-box col-4 col-sm-3 col-md-2">C++</div>
              <div className="skill-box col-4 col-sm-3 col-md-2">C++</div>
              <div className="skill-box col-4 col-sm-3 col-md-2">C++</div>
            </div>
          </section>
          {/* courses  */}
          <section id="courses-container">
            <h1 className="h-primary">COURSES</h1>
            <ul id="courses">
              <li>Introduction to MySQL</li>
              <li>Introduction to MySQL</li>
            </ul>
          </section>
          {/* por  */}
          <section id="por-section">
            <h1 className="h-primary">POR</h1>
            <div id="por">
              <div className="por-box mb-3">
                <h5>Joint Secretary at SME</h5>
                <p className="small">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Molestias, doloribus illo alias animi odit esse odio placeat
                  dicta accusantium saepe debitis, ipsum deserunt distinctio
                  commodi molestiae, repellendus tempora magnam dolores!
                </p>
              </div>
              <div className="por-box mb-3">
                <h5>Joint Secretary at SME</h5>
                <p className="small">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Molestias, doloribus illo alias animi odit esse odio placeat
                  dicta accusantium saepe debitis, ipsum deserunt distinctio
                  commodi molestiae, repellendus tempora magnam dolores!
                </p>
              </div>
            </div>
          </section>
          {/* achievements  */}
          <section id="achievements-section">
            <h1 className="h-primary">ACHIEVEMENTS</h1>
            <ul id="achievements">
              <li>Best Students award in High School</li>
              <li>Best Students award in High School</li>
            </ul>
          </section>
          {/* activities  */}
          <section id="activities-section">
            <h1 className="h-primary">ACTIVITIES</h1>
            <ul id="activities">
              <li>Best Students award in High School</li>
              <li>Best Students award in High School</li>
            </ul>
          </section>
          {/* hobbies */}
          <section id="hobbies-section">
            <h1 className="h-primary">HOBBIES</h1>
            <ul id="hobbies">
              <li>Best Students award in High School</li>
              <li>Best Students award in High School</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
