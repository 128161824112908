import React from "react";
import defaultValues from "../../data/defaultValues";
import "../../css/edit/resume1.css";
import SkillStar from "./SkillStar";
import Arrow from "./Arrow";

export default function Resume1({ setEditBox, name }) {
  const {
    dname,
    role,
    email,
    phone,
    address,
    linkedin,
    summary,
    skills,
    experience,
    education,
    extraActivites,
    languages,
    awards,
  } = defaultValues[0];
  return (
    <div id="parent">
      <div className="bg-white rounded-circle">
        <div id="resume1" className="bg-white container p-3">
          <header className="d-flex justify-content-between position-relative pt-2">
            <Arrow className="header-arrow" setEditBox={setEditBox} />
            {/* name and role  */}
            <div className="header-left">
              <h5 className="name title">{name}</h5>
              <p>{role}</p>
            </div>
            {/* social: email, mobile, address, linkedin  */}
            <div className="header-right">
              <div
                className="social d-flex align-items-center justify-content-between"
                id="email"
              >
                <small className="me-2">{email}</small>
                <i className="fa-solid fa-envelope" />
              </div>
              <div
                className="social d-flex align-items-center justify-content-between"
                id="mobile"
              >
                <small className="me-2">
                  {phone.countryCode + " " + phone.phoneNumber}
                </small>
                <i className="fa-solid fa-mobile-screen" />
              </div>
              <div
                className="social d-flex align-items-center justify-content-between"
                id="address"
              >
                <small>
                  {address.city + ", " + address.state + ", " + address.country}
                </small>
                <i className="fa-solid fa-location-dot" />
              </div>
              <div
                className="social d-flex align-items-center justify-content-between"
                id="address"
              >
                <a href={linkedin} className="me-1">
                  <small>{linkedin}</small>
                </a>
                <i className="fa-brands fa-linkedin-in" />
              </div>
            </div>
          </header>
          <hr style={{ border: "2px solid black" }} />
          <main>
            {/* summary  */}
            <section
              id="summary-container"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow />
              <div className="text-info col-2">
                <h4 className="h-primary">SUMMARY</h4>
              </div>
              <p id="summary" className="col-8 secondary-text">
                {summary}
              </p>
            </section>
            {/* skills  */}
            <section
              id="skill-container"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow />
              <div className="text-info col-2">
                <h4 className="h-primary">SKILLS</h4>
              </div>
              <div id="skills" className="col-8">
                {skills.map((skill, id) => {
                  return (
                    <div
                      className="skill-box d-flex justify-content-between"
                      key={id}
                    >
                      <div className="skill-name small">{skill[0]}</div>

                      <SkillStar star={skill[1]} />
                    </div>
                  );
                })}
              </div>
            </section>
            {/* experience  */}
            <section
              id="experience-container"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow />
              <div className="text-info col-2">
                <h4 className="h-primary">EXPERIENCE</h4>
              </div>
              <div id="experience" className="col-8">
                {experience.map((exp, id) => {
                  return (
                    <div className="experience-box mb-3" key={id}>
                      <h5 className="job-role">{exp.jobRole} </h5>
                      <div className="job-company">{exp.companyName}</div>
                      <div className="job-date small text-muted">
                        {exp.jobtiming.startDate +
                          " - " +
                          exp.jobtiming.endDate}
                      </div>
                      <p className="job-desc secondary-text">
                        {exp.jobDescription}
                      </p>
                    </div>
                  );
                })}
              </div>
            </section>
            {/* education  */}
            <section
              id="education-container"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow />
              <div className="text-info col-2">
                <h4 className="h-primary">EDUCATION</h4>
              </div>
              <div id="education" className="col-8">
                {education.map((edu, id) => {
                  return (
                    <div className="education-box mb-3" key={id}>
                      <h5 className="education-subject">{edu.degree}</h5>
                      <p className="education-college mb-1">
                        {edu.collegeName}
                      </p>
                      <p className="date ">
                        {edu.degreetiming.startDate +
                          " - " +
                          edu.degreetiming.endDate}
                      </p>
                    </div>
                  );
                })}
              </div>
            </section>
            {/* extra activities  */}
            <section
              id="ativities-container"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow />
              <div className="text-info col-2">
                <h4 className="h-primary">EXTRA ACTIVITIES</h4>
              </div>
              <div id="activites" className="col-8">
                {extraActivites.map((activity, id) => {
                  return (
                    <div className="activities-box mb-3" key={id}>
                      <h5 className="activity-name">{activity.activityName}</h5>
                      <p className="activity-description small">
                        {activity.activityDescription}
                      </p>
                    </div>
                  );
                })}
              </div>
            </section>
            {/* languages  */}
            <section
              id="languages-container"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow />
              <div className="text-info col-2">
                <h4 className="h-primary">LANGUAGES</h4>
              </div>
              <div className="languages col-8">
                {languages.map((language, id) => {
                  return (
                    <div
                      className="language-box d-flex justify-content-between"
                      key={id}
                    >
                      <div className="language-name h6">{language[0]}</div>
                      <SkillStar star={language[1]} />
                    </div>
                  );
                })}
              </div>
            </section>
            {/* Award  */}
            <section
              id="award-section"
              className="row justify-content-between align-items-baseline position-relative"
            >
              <Arrow style={{}} />
              <div className="text-info col-2">
                <h4 className="h-primary">AWARDS</h4>
              </div>
              <div className="award col-8">
                {awards.map((award, id) => {
                  return (
                    <h6 key={id} className="pb-2">
                      {award}
                    </h6>
                  );
                })}
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
}
